import { addCmsUrl } from '@autobid/ui/utils/addCmsUrl'
import { addBlankToExternalLinks } from '@autobid/ui/utils/addBlankToExternalLinks'
import { useCmsConfig } from '@autobid/strapi-integration/composable/useCmsConfig'

export const useCkeditor = () => {
  const { url: cmsUrl } = useCmsConfig()
  const { SELF_URL } = useRuntimeConfig().public
  const { host } = new URL(SELF_URL)

  const formatContent = (html: string) => {
    let result = addCmsUrl(html, cmsUrl)

    result = addBlankToExternalLinks(result, host)

    return result
  }

  return { formatContent }
}
